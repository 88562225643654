<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <h4>Objectif de: {{ commercial.full_name }}</h4>
        <Toolbar class="mb-4" v-if="Permissions.add">
          <template v-slot:start>
            <div class="my-2">
              <Button
                label="Ajouter un Objectif"
                icon="pi pi-plus"
                class="p-button-success mr-2"
                @click="showAddCLient()"
              />
            </div>
          </template>
        </Toolbar>
        <DataTable
          :value="objectifs"
          :paginator="true"
          class="p-datatable-gridlines"
          :rows="10"
          dataKey="id"
          :rowHover="true"
          v-model:filters="filters1"
          :loading="loading1"
          :filters="filters1"
          responsiveLayout="scroll"
          :globalFilterFields="[
            'typeObjectif',
            'valeurObjectif',
            'dateD',
            'dateF',
            'Totalcommande',
          ]"
        >
          <template #empty> Aucun data trouvé. </template>
          <template #loading>
            Chargement des données Utilisateurs. Veuillez patienter.
          </template>
          <Column
            field="typeObjectif"
            header="Type d'objectif"
            style="min-width: 12rem"
            :sortable="true"
          >
            <template #body="{ data }">
              {{ data.typeObjectif }}
            </template>
          </Column>
          <Column
            field="valeurObjectif"
            header="Valuer d'objectif"
            style="min-width: 12rem"
            :sortable="true"
          >
            <template #body="{ data }">
              {{ data.valeurObjectif }}
            </template>
          </Column>
          <Column
            field="Totalcommande"
            header="Résultats"
            style="min-width: 12rem"
            :sortable="true"
          >
            <template #body="{ data }">
              {{ data.Totalcommande }}
            </template>
          </Column>
          <Column
            field="dateD"
            header="Date de début"
            style="min-width: 12rem"
            :sortable="true"
          >
            <template #body="{ data }">
              {{ data.dateD }}
            </template>
          </Column>
          <Column
            field="dateF"
            header="Date de fin"
            style="min-width: 12rem"
            :sortable="true"
          >
            <template #body="{ data }">
              {{ data.dateF }}
            </template>
          </Column>

          <Column header="Actions" style="min-width: 12rem">
            <template #body="data">
              <span class="p-buttonset">
                <Button
                  v-if="Permissions.update"
                  style="margin-right: 6px"
                  icon="pi pi-user-edit"
                  class="p-button-raised p-button-rounded p-button-info"
                  @click="updateObjectif(data.data)"
                />
                <Button
                  v-if="Permissions.delete"
                  icon="pi pi-trash"
                  class="p-button-raised p-button-rounded p-button-danger"
                  @click="deleteObjectif(data.data.id)"
                />
              </span>
            </template>
          </Column>
        </DataTable>
        <Dialog
          v-model:visible="dialogOfAdd"
          :style="{ width: '900px' }"
          header="Ajouter un Objectif"
          :modal="true"
          class="p-fluid"
        >
          <div class="card">
            <div class="p-fluid formgrid grid">
              <div class="field col-12 md:col-6">
                <label for="firstname2">Type d'objectif</label>
                <Dropdown
                  v-model="objectif.typeObjectif"
                  :options="cities"
                  :class="{ 'is-invalid': errorsAdd['typeObjectif'] }"
                />

                <small class="p-error" v-if="errorsAdd['typeObjectif']">
                  {{ errorsAdd["typeObjectif"] }}
                </small>
              </div>
              <div class="field col-12 md:col-6">
                <label for="firstname2">Valuer d'objectif</label>
                <InputNumber
                  v-model="objectif.valeurObjectif"
                  type="text"
                  :class="{ 'is-invalid': errorsAdd['valeurObjectif'] }"
                />
                <small class="p-error" v-if="errorsAdd['valeurObjectif']">
                  {{ errorsAdd["valeurObjectif"] }}
                </small>
              </div>
              <div class="field col-12 md:col-6">
                <label for="firstname2">Date de début</label>
                <Calendar
                  :class="{ 'is-invalid': errorsAdd['dateD'] }"
                  v-model="objectif.dateD"
                  dateFormat="dd.mm.yy"
                />
                <small class="p-error" v-if="errorsAdd['dateD']">
                  {{ errorsAdd["dateD"] }}
                </small>
              </div>
              <div class="field col-12 md:col-6">
                <label for="firstname2">Date de fin</label>
                <Calendar
                  :class="{ 'is-invalid': errorsAdd['dateF'] }"
                  v-model="objectif.dateF"
                  dateFormat="dd.mm.yy"
                />
                <small class="p-error" v-if="errorsAdd['dateF']">
                  {{ errorsAdd["dateF"] }}
                </small>
              </div>
              <!-- <div class="field col-12">
                <label for="lastname2" style="text-align: center">Statut</label>
                <InputSwitch
                  v-model="clientToAddstatusswitch"
                  @change="onswitchAdd"
                />
              </div> -->
            </div>
          </div>
          <template #footer>
            <Button
              label="Annuler"
              icon="pi pi-times"
              class="p-button-text"
              @click="dialogOfAdd = false"
            />
            <Button
              label="ajouter"
              icon="pi pi-check"
              class="p-button-text"
              @click="checkFormAdd"
            />
          </template>
        </Dialog>
        <Dialog
          v-model:visible="dialogOfEdit"
          :style="{ width: '900px' }"
          header="Modifier"
          :modal="true"
          class="p-fluid"
        >
          <div class="card">
            <div class="p-fluid formgrid grid">
              <div class="field col-12 md:col-6">
                <label for="firstname2">Type d'objectif</label>
                <Dropdown
                  v-model="objectif.typeObjectif"
                  :options="cities"
                  :class="{ 'is-invalid': errorsAdd['typeObjectif'] }"
                />

                <small class="p-error" v-if="errorsAdd['typeObjectif']">
                  {{ errorsAdd["typeObjectif"] }}
                </small>
              </div>
              <div class="field col-12 md:col-6">
                <label for="firstname2">Valuer d'objectif</label>
                <InputNumber
                  v-model="objectif.valeurObjectif"
                  type="text"
                  :class="{ 'is-invalid': errorsAdd['valeurObjectif'] }"
                />
                <small class="p-error" v-if="errorsAdd['valeurObjectif']">
                  {{ errorsAdd["valeurObjectif"] }}
                </small>
              </div>
              <div class="field col-12 md:col-6">
                <label for="firstname2">Date de début</label>
                <Calendar
                  :class="{ 'is-invalid': errorsAdd['dateD'] }"
                  v-model="objectif.dateD"
                  dateFormat="dd.mm.yy"
                />
                <small class="p-error" v-if="errorsAdd['dateD']">
                  {{ errorsAdd["dateD"] }}
                </small>
              </div>
              <div class="field col-12 md:col-6">
                <label for="firstname2">Date de fin</label>
                <Calendar
                  :class="{ 'is-invalid': errorsAdd['dateF'] }"
                  v-model="objectif.dateF"
                  dateFormat="dd.mm.yy"
                />
                <small class="p-error" v-if="errorsAdd['dateF']">
                  {{ errorsAdd["dateF"] }}
                </small>
              </div>
              <!-- <div class="field col-12">
                <label for="lastname2" style="text-align: center">Statut</label>
                <InputSwitch
                  v-model="clientToAddstatusswitch"
                  @change="onswitchAdd"
                />
              </div> -->
            </div>
          </div>
          <template #footer>
            <Button
              label="Annuler"
              icon="pi pi-times"
              class="p-button-text"
              @click="dialogOfAdd = false"
            />
            <Button
              label="Modifier"
              icon="pi pi-check"
              class="p-button-text"
              @click="checkFormAdd"
            />
          </template>
        </Dialog>
        <!-- <Dialog
          v-model:visible="dialogOfShow"
          :style="{ width: '450px' }"
          header="Détails du Utilisateur"
          :modal="true"
          class="p-fluid"
        >
          Nom : {{ clientToShow.nom }}
          <Divider />
          Prénom :{{ clientToShow.prenom }}
          <Divider />
          Email :{{ clientToShow.email }}
          <Divider />
          Type :{{ clientToShow.name }}
          <Divider />
          Téléphone :{{ clientToShow.tele }}
          <Divider />
          Email :{{ clientToShow.email }}
          <Divider />
          Gsm :{{ clientToShow.gsm }}
          <template #footer>
            <Button
              label="Cancel"
              icon="pi pi-times"
              class="p-button-text"
              @click="dialogOfShow = false"
            />
          </template>
        </Dialog> -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import moment from "moment";
export default {
  data() {
    return {
      Permissions: { update: false, delete: false, add: false, show: false },
      objectifs: [],
      objectif: {
        id: null,
        typeObjectif: null,
        valeurObjectif: null,
        user_id: null,
        dateD: null,
        dateF: null,
      },
      cities: ["unité", "kg", "monétaire"],
      filters1: null,
      loading1: true,
      display: false,
      dialogOfShow: false,
      clientToShow: {},
      commercial: {},
      id: null,
      iduser: null,
      errorsAdd: null,
      dialogOfAdd: false,
      dialogOfEdit: false,
    };
  },
  mounted() {},

  created() {
    this.iduser = this.$route.params.id;
    console.log(localStorage.permissionNames);
    this.Permissions.update =
      localStorage.permissionNames.includes("Modifier objectif");
    this.Permissions.delete =
      localStorage.permissionNames.includes("Supprimer objectif");
    this.Permissions.add =
      localStorage.permissionNames.includes("Ajouter objectif");
    this.Permissions.show =
      localStorage.permissionNames.includes("Liste objectif");
    if (this.Permissions.show) {
      this.showAllclients();
    } else {
      this.$router.push("/");
    }
    this.initFilters1();
  },
  methods: {
    deleteObjectif(id) {
      this.$swal({
        icon: "warning",
        title: "Vous êtes sûr de vouloir continuer ?",
        showDenyButton: true,
        confirmButtonText: "supprimer",
        denyButtonText: `Annuler`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          axios
            .post("deleteObjectif/" + id)
            .then((response) => {
              var index = this.objectifs
                .map((x) => {
                  return x.id;
                })
                .indexOf(id);
              this.objectifs.splice(index, 1);

              this.$swal({
                icon: "success",
                title: response.data.message,
                showConfirmButton: false,
                timer: 1500,
              });
            })
            .catch(function (error) {
           if (error.response.status == 401) {
            localStorage.removeItem("permissionNames");
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                window.location.replace("/");
            // self.$router.push({ path: "/login" });
           // console.log(this.selectedCiasse);
          }
        });
        }
      });
    },
    updateobjectif() {
      this.objectif.user_id = this.iduser;
      this.objectif.dateD = moment(this.objectif.dateD).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      this.objectif.dateF = moment(this.objectif.dateF).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      axios
        .post("modifierObjectif/" + this.objectif.id, this.objectif)
        .then((response) => {
          this.showAllclients();
          this.$swal({
            icon: "success",
            title: response.data.message,
            showConfirmButton: false,
            timer: 1500,
          });
          this.dialogOfEdit = false;
        })
        .catch(function (error) {
           if (error.response.status == 401) {
            localStorage.removeItem("permissionNames");
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                window.location.replace("/");
            // self.$router.push({ path: "/login" });
           // console.log(this.selectedCiasse);
          }
        });
    },
    addobjectif() {
      this.objectif.user_id = this.iduser;
      this.objectif.dateD = moment(this.objectif.dateD).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      this.objectif.dateF = moment(this.objectif.dateF).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      axios
        .post("addObjectif", this.objectif)
        .then((response) => {
          console.log(response);
          this.showAllclients();
          this.$swal({
            icon: "success",
            title: response.data.message,
            showConfirmButton: false,
            timer: 1500,
          });
          this.dialogOfAdd = false;
        })
        .catch(function (error) {
           if (error.response.status == 401) {
            localStorage.removeItem("permissionNames");
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                window.location.replace("/");
            // self.$router.push({ path: "/login" });
           // console.log(this.selectedCiasse);
          }
        });
    },
    checkFormAdd() {
      let checked = true;
      this.errorsAdd = [];
      if (
        this.objectif.valeurObjectif == null ||
        this.objectif.valeurObjectif == ""
      ) {
        this.errorsAdd["valeurObjectif"] = "Valuer d'objectif est obligatoire.";
        checked = false;
      }
      if (this.objectif.typeObjectif == null) {
        this.errorsAdd["typeObjectif"] = "Type d'objectif est obligatoire.";
        checked = false;
      }
      if (this.objectif.dateD == null) {
        this.errorsAdd["dateD"] = "Date de début est obligatoire.";
        checked = false;
      }
      if (this.objectif.dateF == null) {
        this.errorsAdd["dateF"] = "Date de fin est obligatoire.";
        checked = false;
      } else if (this.objectif.dateD > this.objectif.dateF) {
        this.errorsAdd["dateF"] =
          "La date de fin doit être moins que la date de début.";
        checked = false;
      }

      if (checked) {
        if (this.dialogOfAdd) {
          this.addobjectif();
        } else {
          this.updateobjectif();
        }
      }
    },
    showAddCLient() {
      this.initformadd();
      this.errorsAdd = [];
      this.dialogOfAdd = true;
    },
    updateObjectif(data) {
      this.initformadd();
      this.objectif = data;
      this.dialogOfEdit = true;
    },
    initformadd() {
      this.objectif.id = null;
      this.objectif.typeObjectif = null;
      this.objectif.valeurObjectif = null;
      this.objectif.user_id = null;
      this.objectif.dateD = null;
      this.objectif.dateF = null;
      this.errorsAdd = [];
    },
    showclientById(id) {
      this.$router.push({ name: "userobjectifs", params: { id: id } });
    },
    showAllclients() {
      axios
        .get("listeObjectifparUtilisateur/" + this.iduser)
        .then((response) => {
          console.log(response);
          this.objectifs = response.data.Data;
          this.commercial = response.data.commercial[0];
          console.log(this.commercial.full_name);
          this.loading1 = false;
        })
        .catch(function (error) {
           if (error.response.status == 401) {
            localStorage.removeItem("permissionNames");
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                window.location.replace("/");
            // self.$router.push({ path: "/login" });
           // console.log(this.selectedCiasse);
          }
        });
    },
    initFilters1() {
      this.filters1 = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        "country.name": {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        date: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
        },
        balance: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
        },
        status: {
          operator: FilterOperator.OR,
          constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
        },
        activity: { value: null, matchMode: FilterMatchMode.BETWEEN },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS },
      };
    },
    clearFilter1() {
      this.initFilters1();
    },
  },
};
</script>
<style scoped lang="scss">
@import "../../assets/demo/badges.scss";
@import "../../assets/demo/badges.scss";
::v-deep(.p-datatable-frozen-tbody) {
  font-weight: bold;
}

::v-deep(.p-datatable-scrollable .p-frozen-column) {
  font-weight: bold;
}
.is-invalid {
  border-color: #e24c4c;
}
.p-inputtext.p-invalid.p-component {
  border-color: #e24c4c;
}
</style>